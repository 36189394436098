import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { SpeakerOffIcon } from "@radix-ui/react-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Loading } from "../common/loading";
import { Button, FlexCol, Input, Label } from "../shared/ui";
import { TailSpin } from "react-loader-spinner";

type DemoType = {
  data: {
    videoUri: string;
    langs: { name: string; id: any; audioUri: string }[];
  };
  trim_start?: number;
  trim_end?: number;
  password?: string;
};

const Demo = () => {
  const { id } = useParams();
  const videoPlayer = useRef<ReactPlayer | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [active, setActive] = useState<string>("original");
  const [isMuted, setMuted] = useState<boolean>(true);
  const [demo, setDemo] = useState<DemoType | null>(null);
  const [password, setPassword] = useState<string>("");
  const [passwordEntered, setPasswordEntered] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isPasswordLoading, setPasswordLoading] = useState<boolean>(false);

  const handleLoad = async () => {
    try {
      if (!id) return;
      const { data } = await axios.get(
        `https://brain-api.deeptune.com/v1/demos/${id}`
      );
      setDemo(data.demo);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    handleLoad();
  }, [id]);

  const handleClick = async (newActive: string) => {
    if (!videoPlayer.current) return;
    const oldAudio = document.getElementById(active) as HTMLAudioElement;
    const newAudio = document.getElementById(newActive) as HTMLAudioElement;
    newAudio.currentTime = videoPlayer.current.getCurrentTime();
    oldAudio.muted = true;
    oldAudio.pause();
    newAudio.muted = false;
    await newAudio.play();
    setActive(newActive);
    setMuted(false);
  };

  const handleEnd = async () => {
    if (!videoPlayer.current) return;
    videoPlayer.current.seekTo(demo?.trim_start || 0);
    const audio = document.getElementById(active) as HTMLAudioElement;
    audio.currentTime = videoPlayer.current.getCurrentTime();
  };

  const handleMute = () => {
    if (!videoPlayer.current) return;
    const video = videoPlayer.current.getInternalPlayer();
    if (video.paused) video.play();
    const audio = document.getElementById(active) as HTMLAudioElement;
    if (audio.muted) {
      audio.currentTime = videoPlayer.current.getCurrentTime();
      audio.muted = false;
      audio.play();
      setMuted(false);
    } else {
      audio.muted = true;
      audio.pause();
      setMuted(true);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!demo) {
    return <Container>Demo not found</Container>;
  }

  if (demo.password?.length && !passwordEntered) {
    return (
      <Container>
        <FlexCol style={{ gap: 8, alignItems: "center" }}>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
            style={{ width: 320 }}
          />
          <Button
            onClick={() => {
              setError("");
              setPasswordLoading(true);
              setTimeout(() => {
                if (password === demo.password) {
                  setPasswordEntered(true);
                } else {
                  setError("Invalid Password");
                }
                setPasswordLoading(false);
              }, Math.random() * 200 + 200);
            }}
            style={{ width: "100%" }}
            disabled={isPasswordLoading || !password.length}
          >
            {isPasswordLoading ? (
              <TailSpin width={13} height={13} color="white" />
            ) : (
              "Continue"
            )}
          </Button>
          {error ? <Label>{error}</Label> : null}
        </FlexCol>
      </Container>
    );
  }

  return (
    <Container>
      <div style={{ height: "100%", width: "100%" }} onClick={handleMute}>
        <ReactPlayer
          ref={videoPlayer}
          url={demo.data?.videoUri}
          height="100%"
          width="100%"
          muted={true}
          playsinline={true}
          playing={true}
          onEnded={handleEnd}
          onSeek={(t) => {
            const audio = document.getElementById(active) as HTMLAudioElement;
            audio.currentTime = t;
            audio.play();
          }}
          style={{ overflow: "hidden" }}
          controls={false}
          config={{
            file: {
              attributes: {
                onLoadedMetadata: (e: any) => {
                  videoPlayer.current?.seekTo(demo.trim_start || 0);
                },
              },
            },
          }}
          onProgress={({ playedSeconds }) => {
            if (demo.trim_end && playedSeconds >= demo.trim_end) {
              videoPlayer.current?.seekTo(demo.trim_start || 0);
            }
          }}
        />
        <MuteButton
          style={{
            opacity: isMuted ? 1 : 0,
          }}
        >
          <SpeakerOffIcon style={{ height: 36, width: 36 }} />
        </MuteButton>
      </div>
      {demo?.data?.langs.map((option) => (
        <audio
          id={option.id}
          muted
          autoPlay
          preload="auto"
          style={{ marginBottom: 100 }}
          key={option.id}
          onLoadedMetadata={(e: any) => {
            e.target.currentTime = demo.trim_start || 0;
          }}
          onTimeUpdate={(e: any) => {
            if (demo.trim_end && e.target.currentTime >= demo.trim_end) {
              e.target.currentTime = demo.trim_start || 0;
            }
          }}
        >
          <source src={option.audioUri} type="audio/mpeg" />
        </audio>
      ))}
      <LangContainer>
        {demo?.data?.langs
          .sort((a, b) => {
            if (a.id === "original") return -1;
            if (b.id === "original") return 1;
            return a.name.localeCompare(b.name);
          })
          .map((option) => (
            <LangButton
              key={option.id}
              isActive={active === option.id}
              onClick={() => handleClick(option.id)}
            >
              {option.name}
            </LangButton>
          ))}
      </LangContainer>
    </Container>
  );
};

const Container = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: calc(100%);
  font-size: 16px;
  font-weight: 500;
  background: black;
  font-family: Inter;
`;

const LangContainer = styled.div`
  position: absolute;
  top: 16px;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  width: calc(100% - 48px);
  max-width: 600px;
  gap: 8px;
`;

const LangButton = styled.button<{ isActive?: boolean }>`
  all: unset;
  cursor: pointer;
  flex: 1;
  background: ${({ isActive }) => (isActive ? "var(--purple)" : "transparent")};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const MuteButton = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 32px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export default Demo;
