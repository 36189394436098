import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { SpeakerOffIcon } from "@radix-ui/react-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Loading } from "../common/loading";

type DemoTypeV2 = {
  langs: { name: string; id: any; videoUri: string }[];
  version?: string;
};

const DemoV2 = () => {
  const { id } = useParams();
  const videoRefs = useRef<{ [id: string]: React.RefObject<ReactPlayer> }>({});

  const [isLoading, setLoading] = useState<boolean>(true);
  const [active, setActive] = useState<string>("original");
  const [isMuted, setMuted] = useState<boolean>(true);
  const [demo, setDemo] = useState<DemoTypeV2 | null>(null);

  const handleLoad = async () => {
    try {
      if (!id) return;
      const { data } = await axios.get(
        `https://brain-api.deeptune.com/v1/demos/${id}`
      );
      console.log(data);
      setDemo(data.demo.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!demo) return;
    demo.langs.forEach((option) => {
      if (!videoRefs.current[option.id]) {
        videoRefs.current[option.id] = React.createRef();
      }
    });
  }, [demo]);

  useEffect(() => {
    if (!id) return;
    handleLoad();
  }, [id]);

  const handleClick = async (newActive: string) => {
    // if (!videoPlayer.current) return;
    const currTime = videoRefs.current[active].current?.getCurrentTime();
    if (!currTime) {
      return;
    }
    console.log("updating time");
    videoRefs.current[newActive].current?.seekTo(currTime, "seconds");
    setActive(newActive);
  };

  const handleEnd = async () => {
    videoRefs.current[active].current?.seekTo(0, "seconds");
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!demo || demo?.version !== "v2") {
    return <Container>Demo not found</Container>;
  }

  return (
    <Container>
      <div
        style={{ height: "100%", width: "100%" }}
        onClick={() => setMuted(!isMuted)}
      >
        {demo.langs.map((option) => (
          <ReactPlayer
            key={option.id}
            ref={videoRefs.current[option.id]}
            url={option.videoUri}
            height="100%"
            width="100%"
            muted={isMuted || active !== option.id}
            playsinline={true}
            playing
            onEnded={handleEnd}
            // onSeek={(t) => {
            //   const audio = document.getElementById(active) as HTMLAudioElement;
            //   audio.currentTime = t;
            //   audio.play();
            // }}
            style={{
              display: active === option.id ? "block" : "none",
              overflow: "hidden",
            }}
            controls={false}
          />
        ))}
        <MuteButton
          style={{
            opacity: isMuted ? 1 : 0,
          }}
        >
          <SpeakerOffIcon style={{ height: 36, width: 36 }} />
        </MuteButton>
      </div>
      <LangContainer>
        {demo.langs
          .sort((a, b) => {
            if (a.id === "original") return -1;
            if (b.id === "original") return 1;
            return a.name.localeCompare(b.name);
          })
          .map((option) => (
            <LangButton
              key={option.id}
              isActive={active === option.id}
              onClick={() => handleClick(option.id)}
            >
              {option.name}
            </LangButton>
          ))}
      </LangContainer>
    </Container>
  );
};

const Container = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: calc(100%);
  font-size: 16px;
  font-weight: 500;
  background: black;
`;

const LangContainer = styled.div`
  position: absolute;
  top: 16px;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  width: calc(100% - 48px);
  max-width: 600px;
  gap: 8px;
`;

const LangButton = styled.button<{ isActive?: boolean }>`
  all: unset;
  cursor: pointer;
  flex: 1;
  background: ${({ isActive }) => (isActive ? "var(--purple)" : "transparent")};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const MuteButton = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 32px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export default DemoV2;
