export const WEBSERVER_URL = process.env.REACT_APP_STUDIO_API_URL || "";

export const CHARACTER_LIMIT = 100;

export const SPEECH_FUN_FACTS = [
  "Honey never spoils; archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still edible.",
  "The Eiffel Tower can be 15 cm taller during the summer due to thermal expansion of the metal.",
  "There are more stars in the universe than grains of sand on all the Earth's beaches.",
  "A leap year happens every four years, but a century leap year only happens if the year is divisible by 400.",
  "The shortest war in history was between Britain and Zanzibar on August 27, 1896. Zanzibar surrendered after 38 minutes.",
  "The longest English word is 189,819 letters long and takes over three hours to pronounce.",
  "There's a species of jellyfish that is biologically immortal.",
  "The inventor of the Pringles can is now buried in one.",
  "A bolt of lightning contains enough energy to toast 100,000 slices of bread.",
  "The Mona Lisa has no eyebrows because it was the fashion in Renaissance Florence to shave them off.",
  "If you could fold a piece of paper 42 times, it would reach the moon.",
  "Sea otters hold hands when they sleep to keep from drifting apart.",
  "Sloths can hold their breath longer than dolphins can.",
  "The human nose can detect over 1 trillion different scents.",
  "An apple, potato, and onion all taste the same if you eat them with your nose plugged.",
  "There are more public libraries in the U.S. than McDonald’s restaurants.",
  "The cigarette lighter was invented before the match.",
  "The inventor of the Frisbee was turned into a Frisbee after he died.",
  "Tigers have striped skin, not just striped fur.",
  "There are more trees on Earth than stars in the Milky Way galaxy.",
  "Mosquitoes are the deadliest animals in the world, responsible for more deaths than any other creature.",
  "Cows have best friends and get stressed when they are separated.",
  "A small child could swim through the veins of a blue whale.",
  "The word 'nerd' was first coined by Dr. Seuss in 'If I Ran the Zoo'.",
  "Cleopatra lived closer in time to the moon landing than to the construction of the Great Pyramid of Giza.",
  "The shortest complete sentence in the English language is 'I am.'",
  "The average person will spend six months of their life waiting for red lights to turn green.",
  "A single strand of spaghetti is called a 'spaghetto'.",
  "In Switzerland, it is illegal to own just one guinea pig because they are social animals.",
  "The inventor of the microwave oven received only $2 for his discovery.",
  "Humans are born with about 300 bones, but adults have only 206.",
  "The first email was sent by Ray Tomlinson to himself in 1971.",
  "A bolt of lightning is five times hotter than the surface of the sun.",
  "A blob of toothpaste on a toothbrush is called a 'nurdle'.",
  "The largest snowflake on record measured 15 inches wide.",
  "There's a basketball court on the top floor of the U.S. Supreme Court building; it's known as the 'Highest Court in the Land'.",
];

export const SPEECH_ADS = [
  "Never lose your keys again with the KeyFinder Pro. Attach, track, and locate with ease.",
  "Want a brighter smile? Try the ShineBright whitening kit for dazzling teeth in just a few days.",
  "Protect your home with the SafeHome security system. Peace of mind at your fingertips.",
  "Want to learn a new language? The LinguaMaster app makes it fun and easy to speak like a native.",
  "Upgrade your sleep with the CozyCloud mattress. Unmatched comfort for the best sleep of your life.",
  "Streamline your cooking with the SmartChef app. Recipes, timers, and grocery lists all in one place.",
  "Boost your confidence with the PerfectBrow kit. Flawless brows in minutes, every time.",
  "Elevate your photography with the SnapPro lens. Capture stunning, high-quality images with ease.",
  "Stay hydrated with the AquaPure water bottle. Built-in filter for fresh, clean water anywhere.",
  "Organize your life with the PlanIt planner. Stay on top of your goals and tasks effortlessly.",
  "Transform your commute with the E-Ride electric scooter. Fast, fun, and eco-friendly transportation.",
  "Pamper yourself with the SpaGlow bath set. Luxurious products for a relaxing spa experience at home.",
  "Unwind with the ChillWave sound machine. Soothing sounds for a peaceful, restful sleep.",
  "Make meal prep a breeze with the SliceSmart kitchen tool. Perfect cuts and slices every time.",
  "Upgrade your tech with the HyperCharge portable charger. Fast, reliable power on the go.",
  "Feel confident in your smile with the FreshBreath mouthwash. Long-lasting freshness and protection.",
  "Stay cool and comfortable with the ChillPad cooling mat. Perfect for hot summer days.",
  "Enhance your yoga practice with the FlexiMat. Non-slip, cushioned, and perfect for all levels.",
  "Protect your skin with the SunShield sunscreen. Broad-spectrum protection for all-day defense.",
  "Transform your garden with the BloomBoost fertilizer. Lush, vibrant plants and flowers with ease.",
  "Stay organized with the DeskMaster organizer. Keep your workspace neat and efficient.",
  "Improve your posture with the AlignPro brace. Comfortable support for better alignment.",
  "Keep your pets healthy with the NutriPets supplements. Tailored nutrition for a happy, active pet.",
  "Elevate your cooking with the FlavorFusion spices. Gourmet blends for delicious meals.",
  "Unwind with the RelaxPro massage chair. Deep tissue relief and ultimate comfort at home.",
  "Stay stylish and warm with the CozyWrap scarf. Fashion meets function in every season.",
  "Stay connected with the ClearCall Bluetooth headset. Crystal clear calls and hands-free convenience.",
  "Upgrade your skincare with the PureGlow cleanser. Gentle, effective, and perfect for all skin types.",
  "Protect your eyes with the VisionGuard glasses. Stylish frames with blue light protection.",
  "Boost your productivity with the TaskMaster app. Organize, prioritize, and conquer your to-do list.",
  "Stay fresh all day with the CoolBreeze deodorant. Powerful protection without the harsh chemicals.",
  "Transform your car with the ShinePro detailing kit. Professional-grade results in your own driveway.",
  "Keep your devices charged with the PowerBank Pro. High-capacity, portable, and reliable.",
  "Elevate your sound with the AudioMaster speakers. Crystal clear audio for any occasion.",
  "Stay fit with the HomeGym app. Personalized workouts and progress tracking in your pocket.",
  "Protect your tech with the SafeCase. Durable, stylish, and perfect for any device.",
  "Upgrade your wardrobe with the StyleBox subscription. Curated fashion delivered to your door.",
  "Transform your meals with the SpiceMaster grinder. Freshly ground spices for delicious flavors.",
  "Stay cozy with the WarmHug blanket. Soft, luxurious, and perfect for any season.",
  "Protect your home with the SecureCam. Smart security with real-time alerts and HD video.",
  "Enhance your beauty routine with the GlowUp kit. Everything you need for a radiant, flawless look.",
  "Stay productive with the FocusMaster desk. Ergonomic design for comfort and efficiency.",
  "Transform your photos with the PhotoPro editor. Easy-to-use tools for stunning results.",
  "Boost your energy with the VitalFuel drink. Natural ingredients for a healthy, sustained boost.",
  "Stay stylish with the TrendSetter app. Discover the latest fashion trends and shop your favorites.",
  "Protect your phone with the ShockGuard case. Durable, stylish, and built to last.",
  "Stay cool with the BreezePro fan. Powerful, portable, and perfect for any space.",
  "Transform your living room with the DecoLights. Customizable LED lighting for a vibrant atmosphere.",
  "Boost your confidence with the PerfectSmile kit. Whiter teeth and a brighter smile in just minutes.",
  "Stay connected with the ChatMate app. Instant messaging, video calls, and more in one place.",
  "Enhance your gaming experience with the GamerPro chair. Ergonomic design for ultimate comfort.",
  "Boost your health with the VitaBlend smoothie mix. Delicious, nutritious, and easy to make.",
  "Stay hydrated with the PureFlow water filter. Fresh, clean water straight from your tap.",
  "Transform your space with the InstaDecor app. Visualize new furniture and decor with ease.",
  "Stay stylish with the TrendAlert subscription. Curated fashion and accessories delivered monthly.",
  "Protect your car with the AutoGuard dash cam. High-definition video for peace of mind on the road.",
  "Boost your productivity with the WorkSmart app. Organize tasks, set goals, and track progress.",
  "Stay fresh all day with the CoolMist body spray. Long-lasting fragrance and powerful protection.",
  "Transform your hair with the SilkSmooth straightener. Sleek, shiny hair with minimal effort.",
  "Enhance your tech with the SmartHub. Connect and control all your devices from one place.",
  "Stay fit with the FlexiBand resistance bands. Versatile, durable, and perfect for any workout.",
  "Protect your skin with the SunBlock sunscreen. Broad-spectrum protection for all-day defense.",
  "Transform your bedroom with the CozySleep bedding. Soft, luxurious, and perfect for a restful night.",
  "Stay organized with the SmartPlanner. Plan, prioritize, and achieve your goals with ease.",
  "Enhance your workouts with the PowerPro dumbbells. Adjustable weights for a versatile workout.",
  "Protect your home with the HomeShield security system. Smart technology for complete peace of mind.",
];

export const SPEECH_MULTILINGUAL = [
  "La miel nunca se echa a perder; los arqueólogos han encontrado vasijas de miel en tumbas egipcias antiguas de más de 3,000 años de antigüedad que aún son comestibles.",
  "Der Eiffelturm kann im Sommer durch die Wärmeausdehnung des Metalls um 15 cm höher sein.",
  "Il y a plus d'étoiles dans l'univers que de grains de sable sur toutes les plages de la Terre.",
  "Um ano bissexto acontece a cada quatro anos, mas um ano bissexto do século só acontece se o ano for divisível por 400.",
  "La guerra más corta de la historia fue entre Gran Bretaña y Zanzíbar el 27 de agosto de 1896. Zanzíbar se rindió después de 38 minutos.",
  "Das längste englische Wort hat 189.819 Buchstaben und dauert über drei Stunden, um es auszusprechen.",
  "Il existe une espèce de méduse biologiquement immortelle.",
  "O inventor da lata de Pringles foi enterrado em uma.",
  "Un rayo contiene suficiente energía para tostar 100,000 rebanadas de pan.",
  "La Joconde n'a pas de sourcils parce que c'était la mode à Florence à la Renaissance de les raser.",
  "Wenn du ein Blatt Papier 42 Mal falten könntest, würde es den Mond erreichen.",
  "As lontras marinhas dão as mãos quando dormem para não se afastarem umas das outras.",
  "Los perezosos pueden contener la respiración más tiempo que los delfines.",
  "Le nez humain peut détecter plus de 1 trillion d'odeurs différentes.",
  "Uma maçã, uma batata e uma cebola têm o mesmo sabor se você comê-las com o nariz tampado.",
  "Es gibt mehr öffentliche Bibliotheken in den USA als McDonald's-Restaurants.",
  "El encendedor de cigarrillos fue inventado antes que el fósforo.",
  "L'inventeur du frisbee a été transformé en frisbee après sa mort.",
  "Tigres haben nicht nur gestreiftes Fell, sondern auch gestreifte Haut.",
  "Existem mais árvores na Terra do que estrelas na Via Láctea.",
  "Los mosquitos son los animales más mortales del mundo, responsables de más muertes que cualquier otra criatura.",
  "Kühe haben beste Freunde und werden gestresst, wenn sie getrennt werden.",
  "Uma criança pequena poderia nadar pelas veias de uma baleia azul.",
  "Le mot 'nerd' a été inventé par Dr. Seuss dans 'Si je dirigeais le zoo'.",
  "Cleopatra vivió más cerca en el tiempo del alunizaje que de la construcción de la Gran Pirámide de Giza.",
  "Der kürzeste vollständige Satz in der englischen Sprache ist 'I am'.",
  "Uma pessoa média passará seis meses de sua vida esperando que os semáforos fiquem verdes.",
  "Un seul brin de spaghetti s'appelle 'spaghetto'.",
  "En Suiza, es ilegal tener solo un conejillo de indias porque son animales sociales.",
  "O inventor do forno de micro-ondas recebeu apenas $2 por sua descoberta.",
  "Die Menschen werden mit etwa 300 Knochen geboren, aber Erwachsene haben nur 206.",
  "Le premier e-mail a été envoyé par Ray Tomlinson à lui-même en 1971.",
  "Un rayo es cinco veces más caliente que la superficie del sol.",
  "Ein Klecks Zahnpasta auf einer Zahnbürste wird 'Nurdle' genannt.",
  "O maior floco de neve já registrado media 15 polegadas de largura.",
  "Il y a un terrain de basket-ball au dernier étage du bâtiment de la Cour suprême des États-Unis; on l'appelle 'la Cour la plus haute du pays'.",
];

export type VoiceOptionType = {
  label: string;
  tags: string[];
  description: string;
  url: string;
};

export const VOICES: VoiceOptionType[] = [
  {
    label: "Michael - Energetic announcer",
    description:
      "Michael's voice is clear and authoritative, perfect for narrating history documentaries. Its steady and engaging tone brings historical events to life, making them both informative and captivating for listeners.",
    tags: ["male", "american", "announcer"],
    url: "https://deeptune-studio.s3.amazonaws.com/voices/c8bab81d-24d7-45f2-873d-64d959bdbe86.wav",
  },
  {
    label: "Ashley - Professional and confident",
    description:
      "Ashley voice is warm and confident, ideal for professional settings and audiobooks. She is smooth and persuasive, making her a natural leader and communicator.",
    tags: ["female", "british", "energetic"],
    url: "https://deeptune-studio.s3.amazonaws.com/voices/7c260735-a9ad-4b5b-b0c0-c78c840eb766.mp3",
  },
  {
    label: "Samuel - Old but full of character",
    tags: ["male", "british", "deep"],
    description:
      "Samuel Harris' voice is gravelly and full of character, perfect for bringing older, seasoned characters to life. Its rich, textured tones add depth and authenticity, making every performance memorable.",
    url: "https://deeptune-voices.s3.amazonaws.com/polle/inference/0452d62b-3396-426c-b1c1-7bec8f14cf51.wav",
  },
];
